<template>
  <!-- 空页面 -->
  <div>
  </div>
</template>

<script>

export default {
  name: 'Empty',
  data() {
    return {
      
    };
  },
  created() {

  },
  mounted(){
    
  },
  methods: {
    
  }
};
</script>
<style lang="scss" scoped>

</style>